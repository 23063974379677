import React, { FC } from 'react';
import type { PageContentBlade } from 'services/cms/types';
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { StructuredText } from 'components/cms';
import { Image } from 'react-datocms';
import cx from 'classnames';

const useStyles = makeStyles((theme) => ({
  pageContentBlade: {
    display: 'flex',
    '& p': {
      fontWeight: 300,
      margin: '1.5rem 0 0 0',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      '&> div:first-child, div:last-child': {
        margin: '0',
      },
      '&> div:last-child': {
        marginTop: '50px',
      },
    },
  },
  leftColumn: {
    marginRight: '2rem',
  },
  rightColumn: {
    marginLeft: '2rem',
  },
  containerColumn: {
    flex: '1',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  reverse: {
    flexDirection: 'row-reverse',
  },
}));

type Props = {
  blade: PageContentBlade;
};

export const ContentBlade: FC<Props> = ({ blade }: Props) => {
  const classes = useStyles();
  const isLeftAligned = blade.isLeftAligned;

  return (
    <Container
      key={blade.id}
      maxWidth="md"
      className={cx(classes.pageContentBlade, isLeftAligned ? null : classes.reverse)}
    >
      <Grid container className={cx(classes.containerColumn, isLeftAligned ? classes.leftColumn : classes.rightColumn)}>
        <StructuredText data={blade.content} />
      </Grid>
      <Grid
        container
        className={cx(classes.containerColumn, isLeftAligned ? classes.rightColumn : classes.leftColumn)}
        justifyContent="center"
        alignItems="flex-end"
      >
        <Image data={blade?.image?.responsiveImage} />
      </Grid>
    </Container>
  );
};
