import { useAsync } from 'hooks/use-async';
import { post } from 'lib/fetcher';
import { mutate } from 'swr';
import { LeadCaptureForm } from '../types';
import { apis } from '../urls';

const submitForm = async (data: LeadCaptureForm) => {
  const result = await post<{ success: true }>(apis.LEAD_CAPTURE, data);
  mutate(apis.LEAD_CAPTURE, null);
  return result;
};

export const useLeadCapture = () => {
  const { error, isExecuting, mutate, result } = useAsync<LeadCaptureForm, { success: true }>(submitForm);
  return { result, isExecuting, error, submit: mutate };
};
