import {
  Block,
  Blockquote,
  Code,
  Heading,
  isHeading,
  isParagraph,
  List,
  ListItem,
  Paragraph,
  renderRule,
  ThematicBreak,
  Record,
  isLink,
  isList,
} from 'datocms-structured-text-utils';
import { Image, StructuredText as DatoCMSStructuredText, StructuredTextDocument } from 'react-datocms';
import { StructuredText as StructuredTextDataType } from 'datocms-structured-text-utils';
import { Grid, makeStyles } from '@material-ui/core';
import { stripZeroWidthChars } from 'utils';
import React, { FC } from 'react';
import { CallToAction, LeadCaptureForm } from 'services/cms';
import { Link, H1, H2, H3, P, TypographyVariant } from 'components';
import { ContentBlade } from './content-blade';
import { TwoColumnBlade } from './two-column-blade';
import type {
  EmployeeContent,
  ExternalVideoBlockRecord,
  FixedPositionContent,
  FounderBlockContent,
  LeadershipTileContent,
  PageContentBlade,
  Quote,
  TableBlockContent,
  TwoColumnContentBlade,
} from 'services/cms/types';
import { QuoteBlock } from './quote-block';
import { FixedPositionContentBlock } from './fixed-position-content-block';
import { FounderBlock } from './founder-block';
import { EmployeeProfile } from './employee-profile';
import { LeadershipTile } from './leadership-tile';
import cx from 'classnames';
import { CallToActionButton } from './call-to-action';
import { TableBlock } from './table-block';
import { ExternalVideoBlock } from './external-video-block';
import { LeadCaptureBlock } from 'features/public/components';
import logger from 'logging';

export type GridItemWrapperFunctionComponent = FC<{
  item: Paragraph | Heading | List | ListItem | Code | Blockquote | Block | ThematicBreak | Record;
}>;

export type StructuredTextProps = GtmTagProps & {
  data: StructuredTextDocument | StructuredTextDataType;
  h1Variant?: TypographyVariant;
  h2Variant?: TypographyVariant;
  h3Variant?: TypographyVariant;
  pVariant?: TypographyVariant;
  h1ClassName?: string;
  h2ClassName?: string;
  h3ClassName?: string;
  aClassName?: string;
  pClassName?: string;
  GridItemWrapper?: GridItemWrapperFunctionComponent;
  filter?: (item: Paragraph | Heading | List | ListItem | Code | Blockquote | Block | ThematicBreak) => boolean;
};

const useStyles = makeStyles({
  p: {
    margin: '0 0 1em 0',
  },
  a: {
    textDecoration: 'none',
  },
  baselineAnchor: {
    color: 'inherit',
    fontSize: 'inherit',
    fontWeight: 'inherit',
    fontFamily: 'inherit',
    letterSpacing: 'inherit',
  },
});

const defaultWrapper: GridItemWrapperFunctionComponent = ({ children, item, ...props }) => (
  <Grid item xs={12} {...props}>
    {children}
  </Grid>
);

export const StructuredText = ({ data, ...props }: StructuredTextProps) => {
  const {
    h1Variant = 'd5',
    h2Variant = 'd6',
    h3Variant = 'sub1',
    pVariant = 'b1',
    h1ClassName,
    h2ClassName,
    h3ClassName,
    pClassName,
    aClassName,
    GridItemWrapper = defaultWrapper,
    filter,
    gtmEventCategory = 'CMS',
  } = props;

  const classes = useStyles();

  let filteredData = data;
  const docData = data as StructuredTextDocument;
  const txtData = data as StructuredTextDataType;

  if (filter && txtData?.value?.document?.children) {
    filteredData = {
      ...txtData,
      value: {
        ...txtData.value,
        document: {
          ...txtData.value.document,
          children: txtData.value.document.children.filter(filter),
        },
      },
    };
  }

  if (filter && docData?.document?.children) {
    filteredData = {
      ...docData,
      document: {
        ...txtData.value.document,
        children: txtData.value.document.children.filter(filter),
      },
    };
  }

  //NOTE: each of the structured text nodes must start with a <Grid item>
  //NOTE: if they support a child strcutured text the child must be wrapper in a responive grid
  return (
    <DatoCMSStructuredText
      data={filteredData}
      customRules={[
        renderRule(isLink, ({ node, children, key }) => {
          const targetValue = node.meta && node.meta.length > 0 && node.meta.find((m) => m.id === 'target')?.value;

          return (
            <Link
              target={targetValue}
              href={node.url}
              className={cx(classes.baselineAnchor, aClassName)}
              key={key}
              gtmEventCategory={gtmEventCategory}
              gtmEventAction="Clicked Link"
            >
              {children}
            </Link>
          );
        }),
        renderRule(isList, ({ node, children, key }) => (
          <GridItemWrapper item={node} key={key}>
            {node.style === 'numbered' ? <ol>{children}</ol> : <ul>{children}</ul>}
          </GridItemWrapper>
        )),
        renderRule(isHeading, ({ node, children, key }) => {
          switch (node.level) {
            case 1:
              return (
                <GridItemWrapper key={key} item={node}>
                  <H1 variant={h1Variant} className={h1ClassName}>
                    {stripZeroWidthChars(children)}
                  </H1>
                </GridItemWrapper>
              );
            case 2:
              return (
                <GridItemWrapper key={key} item={node}>
                  <H2 variant={h2Variant} className={h2ClassName}>
                    {stripZeroWidthChars(children)}
                  </H2>
                </GridItemWrapper>
              );
            case 3:
              return (
                <GridItemWrapper key={key} item={node}>
                  <H3 variant={h3Variant} className={h3ClassName}>
                    {stripZeroWidthChars(children)}
                  </H3>
                </GridItemWrapper>
              );
            default:
              return (
                <GridItemWrapper key={key} item={node}>
                  <H1 variant={h1Variant} className={h1ClassName}>
                    {stripZeroWidthChars(children)}
                  </H1>
                </GridItemWrapper>
              );
          }
        }),
        renderRule(isParagraph, ({ children, key, node }) => {
          return typeof children === 'string' || (children.length && children[0] && typeof children[0] === 'string') ? (
            <GridItemWrapper item={node} key={key}>
              <P variant={pVariant} className={pClassName || classes.p}>
                {stripZeroWidthChars(children)}
              </P>
            </GridItemWrapper>
          ) : node &&
            node.children &&
            node.children[0] &&
            (node.children[0].type === 'span' || node.children[0].type === 'link') ? (
            <>
              <GridItemWrapper item={node} key={key}>
                <P key={key} variant={pVariant} className={pClassName || classes.p}>
                  {children}
                </P>
              </GridItemWrapper>
            </>
          ) : (
            <>{children}</>
          );
        }),
      ]}
      renderInlineRecord={({ record }) => {
        switch (record.__typename) {
          case 'EmployeeRecord': {
            const content = record as unknown as EmployeeContent;
            return (
              <GridItemWrapper item={null} key={record.id}>
                <EmployeeProfile content={content} key={content.name} />
              </GridItemWrapper>
            );
          }
          default:
            return null;
        }
      }}
      renderBlock={({ record }) => {
        logger.debug(record);

        switch (record.__typename) {
          case 'ImageBlockRecord': {
            // @ts-expect-error: Dynamic record property
            const img = record?.image?.responsiveImage;

            if (img) {
              return (
                <GridItemWrapper key={record.id} item={record}>
                  <Image data={img} />
                </GridItemWrapper>
              );
            }
            return null;
          }
          case 'CallToActionRecord': {
            const cta = record as unknown as CallToAction;
            return (
              <GridItemWrapper key={record.id} item={record}>
                <CallToActionButton
                  className={classes.a}
                  {...cta}
                  gtmEventCategory={cta.gtmEventCategory || gtmEventCategory}
                  gtmEventAction={cta.gtmEventAction || 'Clicked CTA'}
                />
              </GridItemWrapper>
            );
          }
          case 'PageContentBladeRecord': {
            const blade = record as unknown as PageContentBlade;
            return <ContentBlade key={record.id} blade={blade} />;
          }
          case 'TwoColumnContentRecord': {
            const blade = record as unknown as TwoColumnContentBlade;
            return <TwoColumnBlade key={record.id} blade={blade} {...props} />;
          }
          case 'QuoteBlockRecord': {
            const quote = record as unknown as Quote;
            return (
              <GridItemWrapper key={record.id} item={record}>
                <QuoteBlock key={record.id} quote={quote} />
              </GridItemWrapper>
            );
          }
          case 'TableBlockRecord': {
            const table = record as unknown as TableBlockContent;
            return (
              <GridItemWrapper key={record.id} item={record}>
                <TableBlock {...table} />
              </GridItemWrapper>
            );
          }
          case 'FixedPositionContentRecord': {
            const contentBlock = record as unknown as FixedPositionContent;
            return <FixedPositionContentBlock key={record.id} content={contentBlock.content} />;
          }
          case 'FounderBlockRecord': {
            const content = record as unknown as FounderBlockContent;
            return <FounderBlock key={record.id} content={content} />;
          }
          case 'LeadershipRecord': {
            const content = record as unknown as LeadershipTileContent;
            return <LeadershipTile key={record.id} tile={content} />;
          }
          case 'ExternalVideoBlockRecord': {
            const content = record as unknown as ExternalVideoBlockRecord;
            return <ExternalVideoBlock key={record.id} content={content} />;
          }
          case 'LeadCaptureFormRecord': {
            const content = record as unknown as LeadCaptureForm;
            return <LeadCaptureBlock form={content} />;
          }
          default:
            return null;
        }
      }}
    />
  );
};
