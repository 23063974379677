import React, { FC } from 'react';
import { makeStyles, Typography, TypographyProps } from '@material-ui/core';
import { ROBOTO_FONT_FAMILY } from 'components/Layout/theme';

const useStyles = makeStyles({
  default: { fontSize: '1.1875rem', fontWeight: 'bold', fontFamily: ROBOTO_FONT_FAMILY },
  subtitle1: { fontSize: '1.0625rem', fontWeight: 'bold', fontFamily: ROBOTO_FONT_FAMILY },
  subtitle2: { fontSize: '1rem', fontWeight: 500, fontFamily: ROBOTO_FONT_FAMILY },
});

interface H3Props extends Omit<TypographyProps<'h3'>, 'variant'> {
  variant?: 'subtitle2' | 'subtitle1' | 'default';
}

/**
 * @deprecated Migrate to H3 Component
 */
export const H3_Legacy: FC<H3Props> = (props) => {
  const classes = useStyles();
  const { children, className, variant = 'default', ...other } = props;

  const map = new Map(
    Object.entries({
      default: classes.default,
      subtitle1: classes.subtitle1,
      subtitle2: classes.subtitle2,
    })
  );

  return (
    <Typography {...other} variant="h3" className={map.get(variant) + (className ? ' ' + className : '')}>
      {children}
    </Typography>
  );
};
