import React from 'react';
import { CallToAction } from 'services/cms';
import { Button, ButtonProps } from '@material-ui/core';
import { trackGenericEvent } from 'utils';
import { matchesSelectedVariant, WithExperimentProps } from 'components/with-experiment';
import { gtmTagPropsToDataset } from 'utils/gtm-tag-utils';

type Props = CallToAction &
  GtmTagProps &
  WithExperimentProps & {
    className?: string;
    children?: (cta: CallToAction) => React.ReactNode;
    size?: ButtonProps['size'];
    noMargin?: boolean;
    fullWidth?: ButtonProps['fullWidth'];
    onClick?: () => void;
  };

export const RegularCallToActionButton = (props: Props) => {
  let onClick = null;
  const gtmProps = gtmTagPropsToDataset(props);

  if (props.trackingEventAction && props.onClick) {
    onClick = (event: MouseEvent) => {
      event.preventDefault();
      trackGenericEvent(props.trackingEventAction);
      props.onClick();
    };
  } else if (props.trackingEventAction) {
    onClick = () => {
      trackGenericEvent(props.trackingEventAction);
    };
  } else if (props.onClick) {
    onClick = (event: MouseEvent) => {
      event.preventDefault();
      props.onClick();
    };
  }

  if (!matchesSelectedVariant(props.selectedVariant, props.experimentVariant?.name)) {
    return null;
  }

  const stylez = {
    backgroundColor: props.backgroundColour?.hex,
    color: props.textColour?.hex,
    border: props.borderColour?.hex ? `${props.borderColour?.hex} 2px solid` : undefined,
    textDecoration: 'none',
    margin: props.noMargin ? '0' : '0 4px',
  };

  return (
    <Button
      className={props.className}
      onClick={onClick}
      href={props.href}
      style={{ ...stylez }}
      size={props.size}
      fullWidth={props.fullWidth}
      variant={props.ctaType === 'Link' ? 'text' : undefined}
      {...gtmProps}
    >
      {props.children ? props.children(props) : props.text}
    </Button>
  );
};
