export const images = {
  CHEVRON_LEFT: '/images/public/chevron-left.svg',
  CHEVRON_RIGHT: '/images/public/chevron-right.svg',
  CHEVRON_RIGHT_BLACK_SMALL: '/images/public/chevron-right-black-small.svg',
  CHEVRON_RIGHT_WHITE_SMALL: '/images/public/chevron-right-white-small.svg',
  LINK: '/images/public/link.svg',
  SHARE: '/images/public/share.svg',
  TWITTER: '/images/public/twitter.svg',
  CHECK_WHITE: '/images/public/check-white.svg',
};

export const pages = {
  ARTICLES: '/articles',
};

export const apis = {
  LEAD_CAPTURE: '/api/leads',
};
