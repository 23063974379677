import React, { FC } from 'react';
import type { FounderBlockContent } from 'services/cms/types';
import { Grid, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { StructuredText } from 'components/cms';
import { Image as DatoImage } from 'react-datocms';
import { Link, Span_Legacy } from 'components';
import Image from 'next/image';
import { MONTBLANC_FONT_FAMILY } from 'components/Layout/theme';
import { images } from 'features/home/urls';
import { StructuredText_Legacy } from './structured-text-legacy';

const useStyles = makeStyles((theme) => ({
  h2: {
    fontSize: '48px',
    color: theme.palette.Vegemite.main,
    [theme.breakpoints.down('sm')]: { margin: '2rem 0 2rem 0' },
    margin: '6rem 0 2rem 0',
  },
  name: {
    fontFamily: MONTBLANC_FONT_FAMILY,
    letterSpacing: '-0.02em',
    color: theme.palette.Vegemite.main,
    fontSize: '28px',
    fontWeight: 700,
  },
  title: {
    fontSize: '17px',
    color: theme.palette.Kangaroo2.main,
    fontWeight: 700,
  },
  blue: {
    fontFamily: MONTBLANC_FONT_FAMILY,
    fontSize: '28px',
    color: theme.palette.Blue.main,
  },
}));

type Props = {
  content: FounderBlockContent;
};

export const FounderBlock: FC<Props> = ({ content }: Props) => {
  const classes = useStyles();

  return (
    <>
      <Hidden mdUp>
        <Grid item xs={12}>
          <StructuredText data={content.content} h2ClassName={classes.h2} filter={(c) => c.type === 'heading'} />
        </Grid>
      </Hidden>
      <Grid item lg={4} md={6} xs={9}>
        {/* className={cx(classes.containerColumn, classes.leftColumn)} */}
        <div>
          <DatoImage data={content.image?.responsiveImage} />
          <Span_Legacy className={classes.name}>{content.name}</Span_Legacy>
          <Span_Legacy className={classes.blue}>.</Span_Legacy>
          <Grid container justifyContent="space-between">
            <Span_Legacy className={classes.title}>{content.title}</Span_Legacy>
            <Link
              target="_blank"
              href={content?.linkedin ? content.linkedin : 'https://www.linkedin.com/in/pete-steel-au/'}
            >
              <Image width={22} height={22} src={images.LINKEDIN} />
            </Link>
          </Grid>
        </div>
      </Grid>
      <Hidden only="md">
        <Grid item xs={3} lg={1}></Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid item xs={12}>
          <StructuredText_Legacy data={content.content} h2ClassName={classes.h2} filter={(c) => c.type !== 'heading'} />
        </Grid>
      </Hidden>
      <Hidden only={['lg', 'xl', 'sm', 'xs']}>
        <Grid item md={6}>
          <StructuredText_Legacy data={content.content} h2ClassName={classes.h2} />
        </Grid>
      </Hidden>
      <Hidden mdDown>
        <Grid item xs={5}>
          <StructuredText data={content.content} h2ClassName={classes.h2} />
        </Grid>
      </Hidden>
    </>
  );
};
