import React, { FC, useState } from 'react';
import type { EmployeeContent } from 'services/cms/types';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { H3_Legacy, Link, Span_Legacy } from 'components';
import Image from 'next/image';

import cx from 'classnames';
import { MONTBLANC_FONT_FAMILY } from 'components/Layout/theme';
import { images } from 'features/home/urls';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    marginBottom: '24px!important',
  },
  overlay: {
    opacity: 0,
    minHeight: '200px',
    width: '100%',
    padding: '33px 24px',
    backgroundColor: 'rgba(0,0,0,0.8)',
    position: 'absolute',
    bottom: '0',
    pointerEvents: 'none',
  },
  name: {
    fontFamily: MONTBLANC_FONT_FAMILY,
    color: theme.palette.Pavlova.main,
  },
  blue: {
    fontFamily: MONTBLANC_FONT_FAMILY,
    letterSpacing: '-0.02em',
    color: theme.palette.Blue2.main,
  },
  title: {
    color: theme.palette.Blue2.main,
    fontSize: '17px',
    fontWeight: 700,
  },
  linkedIn: {
    pointerEvents: 'all',
  },
  description: {
    color: theme.palette.Pavlova.main,
    fontSize: '13px',
    fontWeight: 500,
    flex: 1,
    marginRight: '16px',
  },
  contentWrapper: {
    position: 'relative',
    '&>div': {
      display: 'block!important',
    },
  },
  overlayInfo: {
    marginTop: '16px',
  },
  isVisible: {
    opacity: 1,
    animation: '0.4s ease-out $fadeInAndUp',
  },
  image: {
    display: 'block!important',
  },
  // eslint-disable-next-line mui-unused-classes/unused-classes
  '@keyframes fadeInAndUp': {
    from: { opacity: 0, transform: 'translate(0px, 100px)' },
    to: { opacity: 1, transform: 'translate(0px, 0)' },
  },
}));

type Props = {
  content: EmployeeContent;
};

export const EmployeeProfile: FC<Props> = ({ content }: Props) => {
  const classes = useStyles();
  const [detailsVisible, setDetailsVisible] = useState(false);

  return (
    <Grid item xs={12} className={classes.root} justifyContent="center">
      <div
        className={classes.contentWrapper}
        onFocus={() => setDetailsVisible(true)}
        onMouseEnter={() => setDetailsVisible(true)}
        onMouseLeave={() => setDetailsVisible(false)}
        onBlur={() => setDetailsVisible(false)}
        onPointerUp={() => setDetailsVisible(!detailsVisible)}
      >
        <Image
          src={content.image?.responsiveImage?.src}
          width={content.image?.responsiveImage?.width}
          height={content.image?.responsiveImage?.height}
          className={classes.image}
        />
        <div className={cx(classes.overlay, detailsVisible ? classes.isVisible : null)}>
          <div>
            <H3_Legacy className={classes.name}>
              {content.name}
              <Span_Legacy className={classes.blue}>.</Span_Legacy>
            </H3_Legacy>
          </div>
          <div>
            <Span_Legacy className={classes.title}>{content.title}</Span_Legacy>
          </div>
          <Grid container alignItems="flex-end" wrap="nowrap" className={classes.overlayInfo}>
            <Span_Legacy className={classes.description}>{content.description}</Span_Legacy>
            <Link target="_blank" href={content.linkedin} className={classes.linkedIn}>
              <Image width={22} height={22} src={images.LINKEDIN_WHITE} className={classes.linkedIn} />
            </Link>
          </Grid>
        </div>
      </div>
    </Grid>
  );
};
