import React, { FC } from 'react';
import { TableBlockContent } from 'services/cms/types';
import { Table, TableHead, TableRow, TableCell, TableBody, Box } from '@material-ui/core';
import { markdownToHtmlSync } from 'utils/markdown-to-html';

const splitUL: (column: string) => string[] = (column) => {
  const re = /[\n\r]\*\s/g;
  const result = column ? ('\r' + column).split(re) : [];
  result.shift();
  return result;
};

const rotateColumns: (headings: string[], columns: Array<string[]>) => Array<string[]> = (headings, columns) => {
  let rowCount = headings.length;
  columns.forEach((c) => (rowCount = Math.max(c.length, rowCount)));

  const rows = [];
  for (let i = 0; i < rowCount; i++) {
    const row = [];
    if (headings.length > 0) {
      row.push(headings[i] ?? '');
    }
    columns.forEach((c) => {
      if (c.length > 0) {
        row.push(markdownToHtmlSync(c[i]) ?? '');
      }
    });
    rows.push(row);
  }
  return rows;
};

export const TableBlock: FC<TableBlockContent> = (props) => {
  const rowHead = splitUL(props.rowHeadings);
  let colHead = splitUL(props.columnHeadings);
  if (rowHead.length > 0 && colHead.length > 0) {
    colHead = ['', ...colHead];
  }

  const rows = rotateColumns(splitUL(props.rowHeadings), [
    splitUL(props.column1),
    splitUL(props.column2),
    splitUL(props.column3),
    splitUL(props.column4),
    splitUL(props.column5),
  ]);

  return (
    <Box mt={4} mb={4}>
      <Table size="small">
        {colHead.length > 0 && (
          <TableHead>
            <TableRow>
              {colHead.map((h, idx) => (
                <TableCell key={idx}>{h}</TableCell>
              ))}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {rows.map((row, idx) => (
            <TableRow key={idx}>
              {row.map((cell, idx) => (
                <TableCell
                  variant={idx === 0 && rowHead.length > 0 ? 'head' : 'body'}
                  key={idx}
                  dangerouslySetInnerHTML={{ __html: cell }}
                ></TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};
