import React, { FC } from 'react';
import { Grid, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { StructuredTextDocument } from 'react-datocms';
import { MONTBLANC_FONT_FAMILY } from 'components/Layout/theme';
import { ResponsiveGrid } from 'components/Layout';
import { StructuredText_Legacy } from './structured-text-legacy';

const useStyles = makeStyles((theme) => ({
  fixedRoot: {
    position: 'fixed',
    top: 'calc(50vh - 70px)',
    width: '100%',
    padding: '0!important',
    pointerEvents: 'none',
  },
  text: {
    fontFamily: MONTBLANC_FONT_FAMILY,
    fontSize: '31px',
    letterSpacing: '-0.025em',
    fontWeight: 700,
    color: theme.palette.Vegemite.main,
  },
}));

type Props = {
  content: StructuredTextDocument;
};

export const FixedPositionContentBlock: FC<Props> = ({ content }: Props) => {
  const classes = useStyles();

  return (
    <>
      <Hidden mdDown>
        <Grid item xs={12} className={classes.fixedRoot}>
          <ResponsiveGrid>
            <Grid item xs={1}></Grid>
            <Grid item xs={5} justifyContent="center">
              <StructuredText_Legacy data={content} pClassName={classes.text} />
            </Grid>
            <Grid item xs={6}></Grid>
          </ResponsiveGrid>
        </Grid>
      </Hidden>
      <Hidden only={['xl', 'lg', 'sm', 'xs']}>
        <Grid item xs={12} className={classes.fixedRoot}>
          <ResponsiveGrid>
            <Grid item xs={6} justifyContent="center">
              <StructuredText_Legacy data={content} pClassName={classes.text} />
            </Grid>
            <Grid item xs={6}></Grid>
          </ResponsiveGrid>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid item xs={12} justifyContent="center">
          <StructuredText_Legacy data={content} pClassName={classes.text} />
        </Grid>
      </Hidden>
    </>
  );
};
