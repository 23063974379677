/* eslint-disable no-misleading-character-class */
/* eslint-disable @typescript-eslint/no-unused-vars */

const ansiEscapeCode = '[\u001b\u009b][[()#;?]*(?:[0-9]{1,4}(?:;[0-9]{0,4})*)?[0-9A-PRZcf-nqry=><]';
const zeroWidthCharsExceptNewline = '\u0000-\u0008\u000B-\u0019\u001b\u009b\u00ad\u200b\u2028\u2029\ufeff\ufe00-\ufe0f';
const zeroWidthCharsExceptNewlineRegex = new RegExp(
  '(?:' + ansiEscapeCode + ')|[' + zeroWidthCharsExceptNewline + ']',
  'g'
);

export function stripZeroWidthChars<T>(value: string | T) {
  return typeof value === 'string' ? value.replace(zeroWidthCharsExceptNewlineRegex, '') : value;
}
