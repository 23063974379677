import React, { FC } from 'react';
import type { LeadershipTileContent } from 'services/cms/types';
import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { StructuredText } from 'components/cms';
import { MONTBLANC_FONT_FAMILY } from 'components/Layout/theme';
import { ResponsiveGrid } from 'components/Layout';

const useStyles = makeStyles((theme) => ({
  experienceH3: {
    fontFamily: MONTBLANC_FONT_FAMILY,
    letterSpacing: '-0.02em',
    fontSize: '96px',
    fontWeight: 700,
    color: theme.palette.Blue.main,
    textAlign: 'right',
    margin: 0,
  },
  h2: {
    margin: '1rem 0 1.5rem 0',
  },
  experienceText: {
    color: theme.palette.Vegemite.main,
    maxWidth: '158px',
    textAlign: 'right',
    margin: '0 0 0 auto',
  },
  p: {
    margin: 0,
  },
}));

type Props = {
  tile: LeadershipTileContent;
};

export const LeadershipTile: FC<Props> = ({ tile }: Props) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} md={6} lg={4} justifyContent="flex-start">
      <Box mb={3}>
        <ResponsiveGrid noGutters>
          <StructuredText
            data={tile.content}
            pVariant="b2"
            h2ClassName={classes.h2}
            h2Variant="d6"
            pClassName={classes.p}
          />
          <StructuredText
            data={tile.experience}
            h3Variant="d3"
            h3ClassName={classes.experienceH3}
            pClassName={classes.experienceText}
            pVariant="b2"
          />
        </ResponsiveGrid>
      </Box>
    </Grid>
  );
};
