// @ts-strict

import useGoogleOptimize from '@react-hook/google-optimize';
import { ComponentType, FC } from 'react';
import { ExperimentVariant } from 'services/cms';
import logger from 'logging';

export type WithExperimentProps = {
  selectedVariant?: ExperimentVariant;
};

export const allExperimentVariants: ExperimentVariant[] = [
  'Original',
  'Challenger1',
  'Challenger2',
  'Challenger3',
  'Challenger4',
  'All',
];

/**
 * HOC that makes it easier to optionally include a Google Optimize experiment
 *
 * @param WrappedComponent The component to wrap with the experiment and provide the selected variant
 * @param variants The list of variants to choose from for the experiment
 * @param experimentId The experiment ID from the Google Optimize dashboard
 * @param defaultVariant If the optimize config is not present, use the default variant to display
 */
export function withExperiment<T extends WithExperimentProps>(
  WrappedComponent: ComponentType<T>,
  experimentId: string,
  variants: ExperimentVariant[] = allExperimentVariants
) {
  logger.debug(`experimentId: ${experimentId}, component: ${WrappedComponent.displayName}`);

  if (!experimentId) {
    const component = ((props: T) => <WrappedComponent selectedVariant={variants[0]} {...props} />) as FC<T>;
    component.displayName = WrappedComponent.displayName;
    return component;
  }

  const ComponentWithExperiment = (props: T) => {
    const selectedVariant = useGoogleOptimize(experimentId, variants);
    logger.debug(`selectedVariant: ${selectedVariant}`);
    return <WrappedComponent selectedVariant={selectedVariant} {...props} />;
  };

  if ('options' in WrappedComponent) {
    // @ts-expect-error .options is specific to TruePage, not taking a dep on that
    ComponentWithExperiment.options = WrappedComponent.options;
  }

  return ComponentWithExperiment;
}

export function matchesSelectedVariant(
  selectedVariant: ExperimentVariant,
  experimentVariant: ExperimentVariant,
  description = ''
) {
  logger.debug(
    `[${description}] matchesSelectedVariant(selectedVariant: ${selectedVariant}, experimentVariant: ${experimentVariant})`
  );

  if (!experimentVariant) {
    return true;
  }

  return experimentVariant === selectedVariant || experimentVariant === 'All';
}
