import { makeStyles } from '@material-ui/styles';
import { ExternalVideoBlockRecord } from 'services/cms';

const useStyles = makeStyles({
  wrapper: {
    position: 'relative',
    paddingBottom: '56.25%' /* 16:9 */,
    height: '0',
    width: '100%',
    overflow: 'hidden',
    '& iframe,object,embed': {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
    },
  },
});

type Props = {
  content: ExternalVideoBlockRecord;
};

export function ExternalVideoBlock(props: Props) {
  const classes = useStyles();
  if (props.content.externalVideo.provider === 'youtube') {
    return (
      <div className={classes.wrapper}>
        <iframe
          width="560"
          height="315"
          src={`//www.youtube.com/embed/${props.content.externalVideo.providerUid}?rel=0`}
          title={props.content.externalVideo.title}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
      </div>
    );
  }

  return null;
}
