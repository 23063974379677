import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { StructuredText } from 'components/cms';
import { Quote } from 'services/cms/types';
import { MONTBLANC_FONT_FAMILY, ROBOTO_FONT_FAMILY } from 'components/Layout/theme';

type Props = {
  quote: Quote;
};

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '0 auto',
    padding: '3rem 0',
  },
  text: {
    fontFamily: MONTBLANC_FONT_FAMILY,
    letterSpacing: '-0.02em',
    fontSize: '24px',
    fontWeight: 700,
    color: ({ quote }: Props) => (quote?.textColour?.hex ? quote.textColour.hex : theme.palette.Blue2.main),
  },
  quotee: {
    fontFamily: ROBOTO_FONT_FAMILY,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.01em',
    marginTop: '8px',
  },
}));

export const QuoteBlock: FC<Props> = ({ quote }: Props) => {
  const classes = useStyles({ quote });

  return (
    <div className={classes.root}>
      <StructuredText data={quote.quote} pVariant="d6" pClassName={classes.text} />
      {quote.quotee && <p className={classes.quotee}>– {quote.quotee}</p>}
    </div>
  );
};
