import React, { useState } from 'react';
import { Button, ClickAwayListener, Grid, IconButton, makeStyles, Modal, TextField } from '@material-ui/core';
import { AsyncButton, H2, P, ResponsiveGrid } from 'components';
import { LeadCaptureForm as LeadCaptureFormCms } from 'services/cms';
import { Field, Form } from 'react-final-form';
import { JsonObject } from 'type-fest';
import { isValidEmail } from 'utils';
import { LeadCaptureForm as FormData } from 'features/public/types';
import { useLeadCapture } from 'features/public/hooks';
import { gtmTagPropsToDataset } from 'utils/gtm-tag-utils';

type Props = {
  form: LeadCaptureFormCms;
  onClose: () => void;
};

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    position: 'relative',
    background: theme.palette.common.white,
    padding: '32px 40px !important',
    borderRadius: 13,
    minHeight: 310,
    outline: 'none',
  },
  item: {
    width: '100%',
  },
  button: {
    width: '100%',
    marginTop: '24px',
    textAlign: 'center',
  },
  closeButton: {
    position: 'absolute',
    top: 5,
    right: 5,
  },
}));

const validate = (values: FormData) => {
  const errors: JsonObject = {};

  if (!values.email || !isValidEmail(values.email)) {
    errors.email = 'Please enter your email address';
  }

  if (!values.name) {
    errors.name = 'Please enter your name';
  }

  return errors;
};

const Success = (props: Pick<Props, 'onClose'>) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} container direction="column" justifyContent="center" alignItems="center">
      <Grid item>
        <img src="/images/check-circle-green.svg" alt="check circle green" />
      </Grid>
      <Grid item>
        <H2 variant="d7">Email sent</H2>
      </Grid>
      <Grid item>
        <P variant="b2">Check your inbox for the report</P>
      </Grid>
      <Grid item className={classes.button}>
        <Button onClick={props.onClose} variant="contained">
          Okay, got it
        </Button>
      </Grid>
    </Grid>
  );
};

const Error = (props: Pick<Props, 'onClose'>) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} container direction="column" justifyContent="center" alignItems="center">
      <Grid item>
        <img src="/images/alert-triangle-black.svg" alt="alert triangle black" />
      </Grid>
      <Grid item>
        <H2 variant="d7">Something&apos;s not right</H2>
      </Grid>
      <Grid item>
        <P variant="b2">An error has occured. Please check your connection and try again.</P>
      </Grid>
      <Grid item className={classes.button}>
        <Button onClick={props.onClose} variant="contained">
          Okay, got it
        </Button>
      </Grid>
    </Grid>
  );
};

export function LeadCaptureForm(props: Props) {
  const classes = useStyles();
  const { submit, error, isExecuting, result } = useLeadCapture();
  const initialFormData: FormData = {
    name: '',
    email: '',
    campaignName: props.form.campaignName,
  };

  const form =
    error || result?.success ? null : (
      <>
        <Grid item>
          <H2 variant="d7" marginTop="none">
            {props.form.header}
          </H2>
        </Grid>
        <Form<FormData> onSubmit={(values) => submit(values)} initialValues={initialFormData} validate={validate}>
          {({ handleSubmit, form, valid, pristine }) => {
            return (
              <Grid
                item
                xs={12}
                container
                component="form"
                direction="column"
                justifyContent="center"
                alignItems="center"
                onSubmit={async (e) => {
                  e.preventDefault();
                  if (valid) {
                    await handleSubmit();
                    form.reset(initialFormData);
                  }
                }}
                noValidate
              >
                <Grid item xs={12} className={classes.item}>
                  <Field<string> name="name">
                    {({ input, meta }) => (
                      <TextField
                        fullWidth
                        label="Name"
                        placeholder="Name"
                        error={Boolean(!pristine && meta.error)}
                        helperText={!pristine && meta.error}
                        {...input}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} className={classes.item}>
                  <Field<string> name="email">
                    {({ input, meta }) => (
                      <TextField
                        fullWidth
                        label="Email"
                        placeholder="Email"
                        type="email"
                        error={Boolean(!pristine && meta.error)}
                        helperText={!pristine && meta.error}
                        {...input}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} className={classes.button}>
                  <AsyncButton
                    isExecuting={isExecuting}
                    disabled={isExecuting || !valid}
                    type="submit"
                    variant="contained"
                    gtmEventCategory={props.form.campaignName}
                    gtmEventAction="Submitted Lead Form"
                    fullWidth
                  >
                    {props.form.submitButtonText}
                  </AsyncButton>
                </Grid>
              </Grid>
            );
          }}
        </Form>
      </>
    );

  return (
    <Modal open onClose={props.onClose}>
      <ClickAwayListener onClickAway={props.onClose}>
        <ResponsiveGrid className={classes.container}>
          <Grid
            className={classes.content}
            item
            xs={12}
            md={6}
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <IconButton
              className={classes.closeButton}
              onClick={props.onClose}
              {...gtmTagPropsToDataset({
                gtmEventCategory: props.form.campaignName,
                gtmEventAction: 'Closed',
              })}
            >
              <img src="/images/closeDialog.svg" alt="close" />
            </IconButton>
            {form}
            {result?.success && <Success onClose={props.onClose} />}
            {error && <Error onClose={props.onClose} />}
          </Grid>
        </ResponsiveGrid>
      </ClickAwayListener>
    </Modal>
  );
}

export function LeadCaptureBlock(props: Pick<Props, 'form'>) {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <AsyncButton
        variant="contained"
        onClick={() => setShowModal(true)}
        gtmEventCategory={props.form.campaignName}
        gtmEventAction="Display Lead Form"
      >
        {props.form.displayButtonText}
      </AsyncButton>
      {showModal && <LeadCaptureForm form={props.form} onClose={() => setShowModal(false)} />}
    </>
  );
}
