import { makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { useRouter } from 'next/router';
import cx from 'classnames';
import { Link, Span, TypographyVariant } from 'components';

const useStyles = makeStyles((theme) => ({
  active: {
    cursor: 'default',

    '&> span': {
      borderBottom: `solid 5px ${theme.palette.Blue.main}`,
      color: theme.palette.Blue.main,
      height: '100%',
      [theme.breakpoints.up('lg')]: {
        display: 'block !important',
        lineHeight: '80px !important',
        boxSizing: 'content-box !important',
        height: '75px !important',
      },
    },
  },
  baseBadge: {
    height: 'fit-content',
    alignSelf: 'center',
    marginLeft: '8px',
    borderRadius: '12px',
    padding: '2px 8px 3px 8px',
  },
  badge: {
    backgroundColor: theme.palette.Dark7.main,
    color: (props: HeaderStyleProps) => (props.isActive ? theme.palette.Blue.main : theme.palette.Vegemite.main),
  },
  badgeHighlighted: {
    backgroundColor: theme.palette.Blue.main,
    color: theme.palette.Pavlova.main,
  },
  baseClass: {
    userSelect: 'none',
    '&:hover': { textDecoration: 'none' },
  },
}));

type HeaderStyleProps = {
  isActive: boolean;
};

type HeaderLinkProps = Required<GtmTagProps> & {
  text: string;
  href: string;
  className?: string;
  badgeText?: string;
  isBadgeHighlighted?: boolean;
  color: string;
  onClick?: () => void;
  variant: TypographyVariant;
};

export const HeaderLink: FC<HeaderLinkProps> = ({
  text,
  href,
  color,
  className,
  badgeText,
  variant,
  isBadgeHighlighted,
  onClick,
  gtmEventCategory,
  gtmEventAction,
}) => {
  const { pathname } = useRouter();
  const classes = useStyles({ isActive: pathname.includes(href) ? true : false });

  const cn = cx(
    {
      [classes.active]: pathname.includes(href) && href !== '/',
    },
    classes.baseClass,
    className
  );

  return (
    <Link
      style={{ color }}
      className={cn}
      href={href}
      underline="hover"
      onClick={onClick}
      {...{ gtmEventCategory, gtmEventAction }}
    >
      <Span variant={variant}>
        {text}
        {badgeText && (
          <Span
            variant={variant}
            marginTop="none"
            className={cx(isBadgeHighlighted ? classes.badgeHighlighted : classes.badge, classes.baseBadge)}
          >
            {badgeText}
          </Span>
        )}
      </Span>
    </Link>
  );
};
