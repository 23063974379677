import { alpha, ButtonProps, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { WithExperimentProps } from 'components';
import { CallToAction } from 'services/cms';
import { pickGtmTagProps } from 'utils/gtm-tag-utils';
import { RegularCallToActionButton } from './regular-call-to-action';

type Props = CallToAction &
  GtmTagProps &
  WithExperimentProps & {
    className?: string;
    size?: ButtonProps['size'];
    fullWidth?: ButtonProps['fullWidth'];
    noMargin?: boolean;
    onClick?: () => void;
  };

const useStyles = makeStyles<Theme, { isExpertAvailable: boolean }>((theme) => ({
  outterDot: {
    width: 17,
    height: 17,
    borderRadius: 8.5,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: (props) =>
      `solid 8px ${alpha(props.isExpertAvailable ? theme.palette.GumTree.main : theme.palette.Kangaroo.main, 0.3)}`,
  },
  innerDot: {
    width: 9,
    height: 9,
    borderRadius: 4.5,
    backgroundColor: (props) => (props.isExpertAvailable ? theme.palette.GumTree.main : theme.palette.Kangaroo.main),
  },
  text: {
    marginLeft: '1em',
  },
}));

export function CallToActionButtonWithAvailability(props: Props) {
  const { availability, isSupported } = { availability: 'disabled', isSupported: false }; // TODO: disabled temporarily. // useCanvasAvailability();
  const classes = useStyles({
    isExpertAvailable: availability === 'available',
  });

  if (!isSupported || availability === 'disabled' || availability === 'offline') {
    return null;
  }

  const { gtmEventAction, gtmEventCategory, ...rest } = props;
  return (
    <RegularCallToActionButton {...rest} {...pickGtmTagProps({ gtmEventAction, gtmEventCategory })} noMargin>
      {(cta) => {
        return (
          <>
            <span className={classes.outterDot}>
              <span className={classes.innerDot}>&nbsp;</span>
            </span>
            <span className={classes.text}>{cta.text}</span>
          </>
        );
      }}
    </RegularCallToActionButton>
  );
}
