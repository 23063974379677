import { CallToAction } from 'services/cms';
import { ButtonProps, Grid, makeStyles } from '@material-ui/core';
import { matchesSelectedVariant, WithExperimentProps } from 'components/with-experiment';
import { RegularCallToActionButton } from './regular-call-to-action';
import { CallToActionButtonWithAvailability } from './call-to-action-with-availability';
import React from 'react';
import { HeaderLink } from 'components';

type Props = CallToAction &
  WithExperimentProps & {
    className?: string;
    size?: ButtonProps['size'];
    fullWidth?: ButtonProps['fullWidth'];
    noMargin?: boolean;
    onClick?: () => void;
  };

const useStyles = makeStyles({
  phone: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    whiteSpace: 'nowrap',
  },
});

export const CallToActionButton = (props: Props) => {
  const classes = useStyles();

  if (!matchesSelectedVariant(props.selectedVariant, props.experimentVariant?.name)) {
    return null;
  }

  if (props.ctaType === 'Start Canvas') {
    return <CallToActionButtonWithAvailability {...props} />;
  }

  if (props.ctaType === 'Call Now') {
    return (
      <RegularCallToActionButton {...props} noMargin>
        {(cta) => {
          return (
            <Grid component="span" container spacing={1} alignItems="center" justifyContent="center" wrap="nowrap">
              <Grid component="span" item className={classes.phone}>
                <img src="/images/phone-blue.svg" alt="phone" />
              </Grid>
              <Grid component="span" item className={classes.text}>
                {cta.text}
              </Grid>
            </Grid>
          );
        }}
      </RegularCallToActionButton>
    );
  }

  if (props.ctaType === 'Header Link') {
    return (
      <HeaderLink
        href={props.href}
        text={props.text}
        color={props.textColour?.hex}
        gtmEventAction={props.gtmEventAction}
        gtmEventCategory={props.gtmEventCategory}
        variant="button1"
      />
    );
  }

  return <RegularCallToActionButton {...props} />;
};
