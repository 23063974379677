import React, { FC } from 'react';
import type { TwoColumnContentBlade } from 'services/cms/types';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ResponsiveGrid, StructuredText, StructuredTextProps } from 'components';

const useStyles = makeStyles(() => ({
  containerColumn: {
    flexDirection: 'column',
  },
}));

type Props = Omit<StructuredTextProps, 'data'> & {
  blade: TwoColumnContentBlade;
};

export const TwoColumnBlade: FC<Props> = ({ blade, GridItemWrapper, ...other }) => {
  const classes = useStyles({ blade });

  return (
    <>
      <Grid item xs={12} md={6} lg={5} className={classes.containerColumn}>
        <ResponsiveGrid noGutters>
          <StructuredText {...other} data={blade.leftContent} />
        </ResponsiveGrid>
      </Grid>
      <Grid item xs={12} md={6} lg={5} className={classes.containerColumn}>
        <ResponsiveGrid noGutters>
          <StructuredText {...other} data={blade.rightContent} />
        </ResponsiveGrid>
      </Grid>
    </>
  );
};
