export const pages = {
  SAVINGS_PAGE: '/mortgage/savings',
  REPAYMENT_SAVINGS_CALCULATOR: '/mortgage/calculators/repayment-savings-calculator',
  BORROWING_POWER_CALCULATOR: '/mortgage/calculators/borrowing-power-calculator',
  BORROWING_POWER: '/mortgage/borrowing-power',
};

export const apis = {
  LOANS_LATEST: '/api/loans/latest',
  LOANS_CASE_OWNER: '/api/loans/case-owner',
  LOANS_BEST_RATE: '/api/loans/best-rate',
  REFINANCE_CALCULATOR: '/api/mortgage/refinance-calculator',
};

export const images = {
  REFINANCE_LEFT: '/images/mortgages/refinance-left.svg',
  REFINANCE_RIGHT: '/images/mortgages/refinance-right.png',
  TICK_WHITE: '/images/mortgages/tick-white.svg',
};
